import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessProjects } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";

const SelectProjectInput = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const projects = useSelector(getSelectedBusinessProjects);

  const defaultPlaceholder = useMemo(() => {
    return projects.some(({ customer }) => customer)
      ? (projects.some(({ customer }) => !customer)
        ? uiTexts.selectProjectOrCustomer : uiTexts.selectCustomer)
      : uiTexts.selectProject;
  }, [projects, uiTexts]);

  const {
    value = {},
    code,
    validate,
    placeholder = defaultPlaceholder,
    onChange,
    ...restProps
  } = props;

  const [showAll, setShowAll] = useState(false);

  const { name: projectName = "" } = value;

  const items = useMemo(() => {
    return projects
      .map(({ id, name, customer }) => ({
        value: id,
        label: name,
        customer: !!customer
      }))
      .sort(({ label: nameA, customer: customerA }, { label: nameB, customer: customerB }) => {
        return (customerA - customerB) || nameA.localeCompare(nameB);
      });
  }, [projects]);

  const filteredItems = useMemo(() => {
    if (!projectName || showAll) return items;

    const localValue = projectName.trim().toLowerCase();

    return items.filter((item) => {
      if (!item.label) return false;

      const itemValue = item.label.trim().toLowerCase();

      if (itemValue === localValue || itemValue.includes(localValue)) {
        return true;
      }

      return Utils.checkSimilarityBy(
        { value: projectName },
        { value: item.label },
        Constants.DATA_SIMILARITY_COEFFICIENT
      );
    });
  }, [projectName, showAll, items]);

  const renderLabel = useCallback(({ label, customer }) => {
    return (
      <div className={Css.listItem} title={label}>
        <span className={Css.name}>{label}</span>
        <Badge theme={customer ? "primary" : ""} className={Css.badge}>
          {customer ? uiTexts.customer : uiTexts.project}
        </Badge>
      </div>
    );
  }, [uiTexts]);

  const handleChange = useCallback((newName) => {
    onChange({ name: newName });
  }, [onChange]);

  const handleAutoComplete = useCallback((item) => {
    const found = Utils.arrayFindById(projects, item.value);

    if (found) {
      const { id, name } = found;

      onChange({ id, name });
    } else {
      onChange({});
    }
  }, [projects, onChange]);

  return (
    <AutoCompleteInput
      {...(validate ? {
        valid: !!(value.name && value.id),
        invalid: !!(value.name && !value.id)
      } : {})}
      {...restProps}
      iconBefore={Icons.Flag}
      iconBeforeTitle={projects.some(({ customer }) => customer)
        ? `${uiTexts.project}/${uiTexts.customer.toLocaleLowerCase()}` : uiTexts.project}
      value={projectName}
      showAll={showAll}
      placeholder={placeholder}
      renderLabel={renderLabel}
      items={items}
      filteredItems={filteredItems}
      onSetShowAll={setShowAll}
      onChange={handleChange}
      onAutoComplete={handleAutoComplete} />
  );
};

export default React.memo(SelectProjectInput);
