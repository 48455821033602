import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getCurrentXeroOrganizationId, getSelectedBusinessItems } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";

const SelectItemInput = (props) => {
  const sourceItems = useSelector(getSelectedBusinessItems);

  const {
    value = {},
    readOnly,
    validate,
    items = sourceItems,
    placeholder,
    paymentType,
    onChange,
    ...restProps
  } = props;

  const { uiTexts } = useSelector(getTextsData);

  const [showAll, setShowAll] = useState(false);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const { name: itemName = "" } = value;

  const sortedItems = useMemo(() => {
    return [...items]
      .filter(({ salesItem, purchaseItem }) => {
        return !xeroBusiness || !paymentType
          || (paymentType === DataConstants.DOCUMENT_PAYMENT_TYPES.BUY ? purchaseItem : salesItem);
      })
      .sort(({ name: nameA = "", type: typeA = "" }, { name: nameB = "", type: typeB = "" }) => {
        return (typeA || "").localeCompare(typeB) || (nameA || "").localeCompare(nameB);
      })
      .map(({ id, name, type }) => ({ value: id, label: name, type }));
  }, [items, paymentType, xeroBusiness]);

  const filteredItems = useMemo(() => {
    if (!itemName || showAll) return sortedItems;

    const localValue = itemName.trim().toLowerCase();

    return sortedItems.filter((item) => {
      if (!item.label) return false;

      const itemValue = item.label.trim().toLowerCase();

      if (itemValue === localValue || itemValue.includes(localValue)) {
        return true;
      }

      return Utils.checkSimilarityBy(
        { value: itemName },
        { value: item.label },
        Constants.DATA_SIMILARITY_COEFFICIENT
      );
    });
  }, [itemName, showAll, sortedItems]);

  const renderLabel = useCallback(({ label, type }) => {
    return (
      <div className={Css.listItem} title={label}>
        <span className={Css.name}>{label}</span>
        <Badge className={Css.badge}>
          {uiTexts[Utils.uncapitalizeText(type)] || uiTexts.other}
        </Badge>
      </div>
    );
  }, [uiTexts]);

  const handleChange = useCallback((newName, event) => {
    onChange({ name: newName }, event);
  }, [onChange]);

  const handleAutoComplete = useCallback((item, event) => {
    onChange(Utils.arrayFindById(sourceItems, item.value), event);
  }, [sourceItems, onChange]);

  return (
    <AutoCompleteInput
      {...(validate ? {
        valid: !!(value.name && value.id),
        invalid: !!(value.name && !value.id)
      } : {})}
      {...restProps}
      iconBefore={Icons.Cube}
      iconBeforeTitle={uiTexts.item}
      readOnly={readOnly}
      value={itemName}
      showAll={showAll}
      placeholder={placeholder || (readOnly ? "" : uiTexts.selectItem)}
      renderLabel={renderLabel}
      items={sortedItems}
      filteredItems={filteredItems}
      onSetShowAll={setShowAll}
      onChange={handleChange}
      onAutoComplete={handleAutoComplete} />
  );
};

export default React.memo(SelectItemInput);
