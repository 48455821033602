import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessLocations } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import AutoCompleteInput from "nlib/ui/AutoCompleteInput";
import Badge from "nlib/ui/Badge";
import Constants from "const/Constants";
import React, { useCallback, useMemo, useState } from "react";
import Utils from "utils/Utils";

const SelectLocationInput = (props) => {
  const { uiTexts } = useSelector(getTextsData);

  const {
    value = {},
    validate,
    readOnly,
    placeholder = readOnly ? "" : uiTexts.selectLocation,
    onChange,
    ...restProps
  } = props;

  const [showAll, setShowAll] = useState(false);

  const { name: locationName = "" } = value;

  const locations = useSelector(getSelectedBusinessLocations);

  const items = useMemo(() => {
    return [...locations]
      .sort(({ name: nameA = "" }, { name: nameB = "" }) => nameA.localeCompare(nameB))
      .map(({ id, name }) => ({ value: id, label: name }));
  }, [locations]);

  const filteredItems = useMemo(() => {
    if (!locationName || showAll) return items;

    const localValue = locationName.trim().toLowerCase();

    return items.filter((item) => {
      if (!item.label) return false;

      const itemValue = item.label.trim().toLowerCase();

      if (itemValue === localValue || itemValue.includes(localValue)) {
        return true;
      }

      return Utils.checkSimilarityBy(
        { value: locationName },
        { value: item.label },
        Constants.DATA_SIMILARITY_COEFFICIENT
      );
    });
  }, [locationName, showAll, items]);

  const renderLabel = useCallback(({ label }) => {
    return (
      <div className={Css.listItem} title={label}>
        <span className={Css.name}>{label}</span>
        <Badge className={Css.badge}>{uiTexts.location}</Badge>
      </div>
    );
  }, [uiTexts]);

  const handleChange = useCallback((newName, event) => {
    onChange({ name: newName }, event);
  }, [onChange]);

  const handleAutoComplete = useCallback((item, event) => {
    onChange(Utils.arrayFindById(locations, item.value), event);
  }, [locations, onChange]);

  return (
    <AutoCompleteInput
      {...(validate ? {
        valid: !!(value.name && value.id),
        invalid: !!(value.name && !value.id)
      } : {})}
      {...restProps}
      iconBefore={Icons.MapPin}
      iconBeforeTitle={uiTexts.location}
      readOnly={readOnly}
      value={locationName}
      showAll={showAll}
      placeholder={placeholder}
      renderLabel={renderLabel}
      items={items}
      filteredItems={filteredItems}
      onSetShowAll={setShowAll}
      onChange={handleChange}
      onAutoComplete={handleAutoComplete} />
  );
};

export default React.memo(SelectLocationInput);
